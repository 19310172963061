export const contacts = {
  phone: {
    text: '8 800 500-21-38',
    value: '88005002138',
  },

  officePhone: {
    text: '+7 495 374-52-70',
    value: '74953745270',
  },

  telegram: 'https://t.me/QRsalesteam',
  whatsApp: 'https://wa.me/89193403883',

  infoEmail: '',
  salesEmail: 'sales@quickresto.ru',
  salesEmailContacts: '',
  supportEmail: 'support@quickresto.ru',
  orderPricesUrl: 'https://quickresto.ru/static/tarifs-d6585efa0198e339d13e5ecfa1c126a0.pdf',
  loyaltyPricesUrl: 'https://quickresto.ru/static/tarifs-loyalty.pdf',

  address: 'Москва, Ленинградский пр. 36, стр. 30, пом. 96.',
}

export const contactsKz = {
  phone: {
    text: '+7 727 312 31 99',
    textContacts: ' +7 (727) 312-31-99 (105)',
    value: '+77273123199',
  },

  officePhone: {
    text: '+7 (727) 312-31-99',
    value: '+77273123199',
  },

  telegram: '',
  whatsApp: '',

  infoEmail: '',
  salesEmail: 'support@quickresto.kz',
  salesEmailContacts: ' d.sonin@quickresto.kz',
  supportEmail: 'support@quickresto.kz',
  orderPricesUrl: 'https://quickresto.ru/static/tariffs-kz.pdf',
  loyaltyPricesUrl: 'https://quickresto.ru/static/tariffs-loyalty-kz.pdf',

  address: 'Алматы, ул. Розыбакиева 247',
}

export const contactsBy = {
  phone: {
    text: '+375 44 7-937-937',
    value: '+375447937937',
  },

  officePhone: {
    text: '',
    value: '',
  },

  address: 'г. Минск, ул.Зыбицкая, д.6, офис 36',

  telegram: 'https://t.me/QR_Belarus',
  whatsApp: '',

  infoEmail: 'support@quickresto.by',
  salesEmail: 'sales@quickresto.by',
  salesEmailContacts: '',
  supportEmail: 'support@quickresto.by',
  orderPricesUrl: 'https://quickresto.ru/static/tariffs-by.pdf',
  loyaltyPricesUrl: 'https://quickresto.ru/static/tariffs-loyalty-by.pdf',
}
