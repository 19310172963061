import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Logo from "../../../assets/_V2/svg/Logo"
import AdaptiveLink from "../AdaptiveLink"
import Backdrop from "./assets/Backdrop"
import VkIcon from "./assets/VkIcon"
import YoutubeIcon from "./assets/YoutubeIcon"
import { getData } from "./data"
import styles from "./styles.module.scss"

import { useLocalizationContext } from "../../../localization/useLocalizationContext";

export enum FOOTER_THEMES {
	COLORED = "colored",
	WHITE = "white",
}

interface Props {
	className?: string

	theme: FOOTER_THEMES
}

export default function Footer(props: Props) {
	const localizationContext = useLocalizationContext()

	const data = getData(localizationContext.locale);

	const isColoredTheme = props.theme === FOOTER_THEMES.COLORED

	const renderLinkList = (
		title: string,
		links: Array<{
			text: string
			titleKz?: string
			href: string
			useGatsbyLink: boolean
			target?: string
		}>
	): JSX.Element => {
		return (
			<>
				<span
					className={classNames(styles.linkListTitle, {
						[styles.linkListTitle_theme_colored]: isColoredTheme,
					})}
				>
					{title}
				</span>

				<ul className={styles.linkList}>
					{links.map(link => (
						<li className={styles.link__wrapper} key={link.text}>
							<AdaptiveLink
								className={styles.link}
								href={link.href}
								useGatsbyLink={link.useGatsbyLink}
								target={link.target}
							>
								{link.text}
							</AdaptiveLink>
						</li>
					))}
				</ul>
			</>
		)
	}

	const renderStoreBadges = (): JSX.Element => {
		if (props.theme === FOOTER_THEMES.COLORED) {
			return (
				<>
					<a
						className={styles.contacts__mobileStoreLink}
						href={data.appstore.href}
						target={"_blank"}
					>
						<StaticImage
							src="./assets/AppStoreBadgeLight.png"
							alt={data.appstore.ariaLabel}
							quality={90}
							width={150}
						/>
					</a>

					<a
						className={styles.contacts__mobileStoreLink}
						href={data.googleplay.link}
						target={"_blank"}
					>
						<StaticImage
							src="./assets/GooglePlayBadgeLight.png"
							alt={data.googleplay.alt}
							quality={90}
							width={150}
						/>
					</a>
					<a
						className={styles.contacts__mobileStoreLink}
						href={data.microsoftStore.link}
						target={"_blank"}
					>
						<StaticImage
							src="./assets/MicrosoftStoreBadgeLight.png"
							alt={data.microsoftStore.alt}
							quality={90}
							width={150}
						/>
					</a>
				</>
			)
		}

		return (
			<>
				<a
					className={styles.contacts__mobileStoreLink}
					href={data.appstore.href}
					target={"_blank"}
				>
					<StaticImage
						src="./assets/AppStoreBadgeDark.png"
						alt={data.appstore.ariaLabel}
						quality={90}
						width={150}
					/>
				</a>

				<a
					className={styles.contacts__mobileStoreLink}
					href={data.googleplay.link}
					target={"_blank"}
				>
					<StaticImage
						src="./assets/GooglePlayBadgeDark.png"
						alt={data.googleplay.alt}
						quality={90}
						width={150}
					/>
				</a>

				<a
					className={styles.contacts__mobileStoreLink}
					href={data.microsoftStore.link}
					target={"_blank"}
				>
					<StaticImage
						src="./assets/MicrosoftStoreBadgeDark.png"
						alt={data.microsoftStore.alt}
						quality={90}
						width={150}
					/>
				</a>
			</>
		)
	}

	return (
		<section
			className={classNames(
				styles.container,
				{
					[styles.__colored]: props.theme === FOOTER_THEMES.COLORED,
					[styles.__white]: props.theme === FOOTER_THEMES.WHITE,
				},
				props.className
			)}
		>
			{props.theme === FOOTER_THEMES.COLORED && (
				<Backdrop className={styles.backdrop} />
			)}

			<div className={styles.companyInfo}>
				{renderLinkList(data.companyInfo.title, data.companyInfo.links)}
			</div>

			<div className={styles.businessTypes}>
				{renderLinkList(data.businessTypes.title, data.businessTypes.links)}
			</div>

			<div className={styles.clientsInfo}>
				{renderLinkList(data.clientsInfo.title, data.clientsInfo.links)}
			</div>

			<div className={styles.contacts}>
				<a href={`mailto:${data.email}`} className={styles.contacts__email}>
					{data.email}
				</a>

				<a href={`tel:${data.phone.value}`} className={styles.contacts__phone}>
					{data.phone.title}
				</a>

				<div className={styles.contacts__socials}>
					<a
						className={styles.contacts__socialLink}
						href={data.vk.href}
						aria-label={data.vk.ariaLabel}
						target="_blank"
					>
						<VkIcon />
					</a>

					<a
						className={styles.contacts__socialLink}
						href={data.youtube.href}
						aria-label={data.youtube.ariaLabel}
						target="_blank"
					>
						<YoutubeIcon />
					</a>
				</div>

				<div className={styles.contacts__mobileStores}>
					{renderStoreBadges()}
				</div>
			</div>

			<div className={styles.legalInfo}>
				<div className={styles.legalInfo__logoWrap}>
					<Logo className={styles.legalInfo__logo}/>

					<span className={styles.legalInfo__copyright}>{data.copyRight}</span>
				</div>
				<span itemScope itemType="r" className="display-none">
       			<meta itemProp="copyrightYear" content={data.copyRightYear}/>
       			<meta itemProp="copyrightHolder" content={data.copyRightCompany}/>
				</span>
				{localizationContext.locale !== 'ru-BY' && (
					<AdaptiveLink
						className={styles.legalInfo__link}
						href={data.licenseAgreement.href}
						useGatsbyLink={data.licenseAgreement.useGatsbyLink}
						target={data.licenseAgreement.target}
					>
					<span className={styles.legalInfo__linkText}>
						{data.licenseAgreement.text}
					</span>
					</AdaptiveLink>
				) }
				<AdaptiveLink
					className={styles.legalInfo__link}
					href={data.privacyPolicy.href}
					useGatsbyLink={data.privacyPolicy.useGatsbyLink}
					target={data.privacyPolicy.target}
				>
					<span className={styles.legalInfo__linkText}>
						{data.privacyPolicy.text}
					</span>
				</AdaptiveLink>

				<div className={styles.legalInfo__partnersContainer}>
					<a
						href={data.skolkovo.link}
						className={styles.legalInfo__skolkovoLink}
						target={"_blank"}
					>
						<StaticImage
							src="../../../assets/_V2/badge_skolkovo.png"
							alt={data.skolkovo.alt}
							quality={90}
							width={55}
						/>
					</a>

					<a
						href={data.oneC.link}
						className={styles.legalInfo__oneCLink}
						target={"_blank"}
					>
						<StaticImage
							src="../../../assets/_V2/badge_1c.png"
							alt={data.oneC.alt}
							quality={100}
							width={131}
						/>
					</a>
				</div>
			</div>
		</section>
	)
}
